<template>
  <v-container>
    <v-btn class="px-2" @click="goBackHandler"
      ><v-icon>mdi-chevron-left</v-icon>Go back</v-btn
    >
    <v-row v-if="Object.keys(this.getInstructionData).length">
      <v-col cols="2" class="d-flex flex-column mt-16 px-0 pb-0">
        <div class="my-5">
          <p class="mb-1"><v-icon>mdi-map-marker</v-icon>From</p>
          <h3><v-icon>mdi-ferry</v-icon>{{ loadingPort }}</h3>
        </div>

        <div class="my-5">
          <p class="mb-1"><v-icon>mdi-map-marker</v-icon>To</p>
          <h3>
            <v-icon>mdi-ferry</v-icon>
            {{ dischargePort }}
          </h3>
        </div>

        <div class="my-5">
          <p class="mb-1">Ship Departs</p>
          <h3><v-icon>mdi-calendar-month</v-icon>{{ choseDateHandler() }}</h3>
        </div>
        <div></div>
      </v-col>
      <v-col>
        <v-col cols="9"><h1>Order Summary</h1></v-col>

        <v-row>
          <v-col class="main-info border_right pb-0" cols="9">
            <v-row class="top-table border-bottom">
              <v-col class="border_right pl-0">
                <h3 class="border-bottom">Supplier</h3>
                <p class="mb-1"><span>Carrier:</span> no data</p>
                <p class="mb-4">
                  {{ loadingPort }} to
                  {{ dischargePort }}
                </p>
                <p>
                  <span> Sailing from</span>
                  {{ loadingPort }}
                </p>
              </v-col>
              <v-col class="border_right">
                <h3 class="border-bottom">ETD</h3>
                <p class="mb-11">
                  {{ this.choseDateHandler() }}
                </p>
                <p>
                  <span>Origin port ETD</span>
                  {{ this.choseDateHandler() }}
                </p>
              </v-col>
              <v-col class="border_right">
                <h3 class="border-bottom">Duration</h3>
                <p class="mb-11">no data</p>
                <p><span>Aboard ship</span> no data</p>
              </v-col>
              <v-col class="pr-0">
                <h3 class="border-bottom">ETA</h3>
                <p class="mb-11">no data</p>
                <p><span>Destination port ETA</span> no data</p>
              </v-col>
            </v-row>
            <v-row class="flex-column border-bottom">
              <p class="gray">Customer Details:</p>
              <p>{{ this.getInstructionData.shipper }}</p>
              <p class="gray">Basic Ocean Freight</p>
              <p>Ship Name: no data</p>
              <p>Voyage Number: no data</p>
            </v-row>
            <v-row
              v-for="(container, index) in getInstructionData.containers"
              :key="index"
              class="flex-column border-bottom"
            >
              <p>
                <span v-if="container.containerType.name"
                  >{{ container.containerType.name }} -
                  {{ container.containerType.size }}</span
                ><span v-else>Container</span>; {{ container.quantity }} - pcs
              </p>
              <p>Container Contents:</p>
              <div v-for="(cargo, index) in container.cargos" :key="index">
                <p>Cargo - {{ index + 1 }}</p>
                <p class="pl-3">{{ cargo.descriptionOfGoods }}</p>
                <p class="pl-3">
                  Weight -
                  <span v-if="!!cargo.grossCargoWeight && cargo.unitOfWeight"
                    >{{ cargo.grossCargoWeight }} {{ cargo.unitOfWeight }}
                  </span>
                  <span v-else>no data</span>
                </p>
              </div>
            </v-row>
          </v-col>
          <!-- <v-col class="total-price pt-6" cols="3">
            <div>
              <h3>Total Price:</h3>
            </div>
            <div v-for="(item, index) in prices" :key="index">
              <p>{{ item.header }}</p>
              <div>
                <span>vStandart - 20 X 3 </span>
                <span>{{ item.price }}</span>
              </div>
            </div>
            <div>
              <h3>Total <span>$3000</span></h3>
            </div>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else-if="!getInstructionDataLoading">
      <v-col class="mx-auto " cols="5">
        <h1>Can't find form with this ID</h1>
      </v-col>
    </v-row>
    <v-row class="py-9">
      <v-btn
        v-if="showConfirm && !getInstructionDataLoading"
        class="mx-auto my-2"
        @click="confirmBooking"
        >Confirm Booking</v-btn
      >
      <v-progress-circular
        v-if="getInstructionDataLoading"
        class="mx-auto"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { instructionFormBuilder } from '../instructionForm/utils';
import { TOAST_TYPE } from '../../utils/constants';
export default {
  name: 'Summary',

  data: () => ({
    //mock data
    prices: [
      {
        header: 'Container managment fee',
        price: '$30'
      },
      {
        header: 'International ship security',
        price: '$30'
      },
      {
        header: 'New bunker factor',
        price: '$1000'
      },
      {
        header: 'New low sulphur surcharge',
        price: '$20'
      },
      {
        header: 'Net freignt',
        price: '$1500'
      },
      {
        header: 'Seal fee',
        price: '$10'
      },
      {
        header: 'Security manifest charge',
        price: '$60'
      },
      {
        header: 'Term. Handling origin',
        price: '$350'
      }
    ]
  }),
  async mounted() {
    if (this.$route.params.id) {
      try {
        await this.getInstructionForm(this.$route.params.id);
      } catch (error) {
        this.setToast({ message: error.message, type: TOAST_TYPE.ERROR });
        console.log('>>>', error, ' GET:ID error  <<<');
      }
    } else if (!Object.keys(this.getInstructionData).length) {
      this.$router.push('/new-booking');
    }
  },
  methods: {
    choseDateHandler() {
      const newDate = new Date(
        this.getInstructionData.departure_date
      ).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
      const formatedDate = newDate.replace(',', '').split(' ');
      return [formatedDate[1], formatedDate[0], formatedDate[2]].join(' ');
    },
    ...mapActions({
      saveInstructionForm: 'instructionForm/saveInstructionForm',
      clearInstructionForm: 'instructionForm/clearInstructionForm',
      getInstructionForm: 'instructionForm/getInstructionForm',
      setToast: 'toast/setToast'
    }),
    async confirmBooking() {
      const body = instructionFormBuilder(this.getInstructionData);
      this.removeOverData();
      try {
        const response = await this.saveInstructionForm(body);
        this.setToast({
          message: 'SuccessfullyRegistered',
          type: TOAST_TYPE.SUCCESS
        });
        this.$router.push('/allInstructions');
        this.clearInstructionForm();
        console.log('>>>', response, ' POST response  <<<');
      } catch (error) {
        console.log('>>>', error, ' POST error  <<<');
        this.setToast({ message: error.message, type: TOAST_TYPE.ERROR });
      }
    },
    removeOverData() {
      for (let container in this.getInstructionData.containers) {
        for (let cargo of this.getInstructionData.containers[container]
          .cargos) {
          delete cargo.codeList;
          delete cargo.codeListLoading;
        }
      }
    },
    goBackHandler() {
      this.$router.push(
        this.$route.params.id ? '/allInstructions' : '/instruction'
      );
    }
  },
  computed: {
    ...mapGetters('instructionForm', [
      'getInstructionData',
      'getInstructionDataLoading'
    ]),

    loadingPort() {
      return this.getInstructionData.loadingPort.port
        ? this.getInstructionData.loadingPort.port
        : 'no data';
    },
    dischargePort() {
      return this.getInstructionData.dischargePort.port
        ? this.getInstructionData.dischargePort.port
        : 'no data';
    },
    showConfirm() {
      return !this.$route.params.id;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.params.id) {
      this.clearInstructionForm();
    }
    next();
  }
};
</script>

<style scoped>
@import './summary.module.css';
</style>
